<template>
  <div>
    <TopBar
      v-on:switchEN="switchEN"
      v-on:switchCZ="switchCZ"
      v-on:switchC="switchC"
      v-on:switchF="switchF"
    />
    <PicsBlock v-bind:currLang="currLang"
        v-bind:onMobile="onMobile"
    />
    <div :class="[onMobile ? 'mapa_meteo_block_col' : 'mapa_meteo_block_row']">
      <MapaBlock
        v-bind:currLang="currLang"
        :class="[onMobile ? 'full_page' : 'half_page']"
      />
      <MeteoBlock
        v-bind:currLang="currLang"
        v-bind:unit="unit"
        :class="[onMobile ? 'full_page' : 'half_page']"
      />
    </div>
    <BottomBar v-bind:currLang="currLang" 
      />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import PicsBlock from "@/components/PicsBlock.vue";
import MapaBlock from "@/components/MapaBlock.vue";
import MeteoBlock from "@/components/MeteoBlock.vue";
import BottomBar from "@/components/BottomBar.vue";

export default {
  name: "Home",
  components: {
    TopBar,
    PicsBlock,
    MapaBlock,
    MeteoBlock,
    BottomBar,
  },
  created(){
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

  },
  destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
  mounted: function () {
		// window.addEventListener(
		// 	"orientationchange",
		// 	this.handlerOrientationChange
		// );
		//		window.onresize = this.resized();
	},
  computed: {
    onMobile() {
      if (this.windowWidth < 640) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      currLang: "cz",
      unit: "C",
    };
  },
  methods: {
    handleResize() {
			this.windowWidth = window.innerWidth;
		},
    // handlerOrientationChange() {
		// 	switch (window.screen.orientation.type) {
		// 		case "portrait-primary":
		// 			this.setCamera2();
		// 		case "landscape-primary":
		// 			this.setCamerasOn();
		// 	}
		// },
    switchEN() {
      this.currLang = "en";
    },
    switchCZ() {
      this.currLang = "cz";
    },
    switchC() {
      this.unit = "C";
    },
    switchF() {
      this.unit = "F";
    },
  },
};
</script>
<style scoped>
.mapa_meteo_block_row {
  display: flex;
  flex-direction: row;
}
.mapa_meteo_block_col {
  display: flex;
  flex-direction: column;
}
.half_page {
  width: 50%;
}
.full_page {
  width: 100%;
}
</style>
