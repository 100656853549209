<template>
  <div class="mapaBlok">
    <div class="hlavickaMapa">
      <p>{{ smerpohledu[currLang] }}</p>
    </div>
    <!-- <div id="m" class="mapa"></div> -->
	<div class="mapa">
		<img src="../assets/Pics/mapaImg.png" alt="">
	</div>
  </div>
</template>

<script>
export default {
    name: "MapaBlock",
    props: { currLang: String},
    mounted: function () {
		this.loadMap();
    },
    data(){
        return{
            smerpohledu: {
			    en: 'View Direction',
			    cz: 'Směr pohledu',
		    },
        }
    },
    methods: {
        loadMap() {
			//var center = SMap.Coords.fromWGS84(16.6118572, 49.2003031);
			var center = SMap.Coords.fromWGS84(16.6089403, 49.2026583);
			var cameraPosition = SMap.Coords.fromWGS84(16.6148050, 49.2044978);
			var m = new SMap(JAK.gel("m"), center, 14);
			m.addDefaultLayer(SMap.DEF_BASE).enable();
			m.addDefaultControls();

			var layer = new SMap.Layer.Marker();
			m.addLayer(layer);
			layer.enable();

			var options = {};
			var marker = new SMap.Marker(cameraPosition, "myMarker", options);
			layer.addMarker(marker);
			// add view lines
			var glayer = new SMap.Layer.Geometry();
			m.addLayer(glayer);
			glayer.enable();

			var points1 = [
				SMap.Coords.fromWGS84(16.6148050, 49.2044978),
				SMap.Coords.fromWGS84(16.4900861, 49.3422975)
				//SMap.Coords.fromWGS84(16.3461356, 49.2476725)
			];
			var points2 = [
				SMap.Coords.fromWGS84(16.6148050, 49.2044978),
				SMap.Coords.fromWGS84(16.3461356, 49.2476725)
				//SMap.Coords.fromWGS84(16.3736953, 49.1318658)
			];
			var points3 = [
				SMap.Coords.fromWGS84(16.6148050, 49.2044978),
				SMap.Coords.fromWGS84(16.3736953, 49.1318658)
				//SMap.Coords.fromWGS84(16.5444567, 49.0525889)
			];
			var points4 = [
				SMap.Coords.fromWGS84(16.6148050, 49.2044978),
				SMap.Coords.fromWGS84(16.5444567, 49.0525889)
				//SMap.Coords.fromWGS84(16.7629978, 49.0510111)
			];
			var points5 = [
				SMap.Coords.fromWGS84(16.6148050, 49.2044978),
				SMap.Coords.fromWGS84(16.7629978, 49.0510111)
				//SMap.Coords.fromWGS84(16.8797589, 49.1588858)
			];
			var options1 = {
				color: "#f00",
				width: 3
			};
			var polyline = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, points1, options1);
			glayer.addGeometry(polyline);
			var polyline = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, points2, options1);
			glayer.addGeometry(polyline);
			var polyline = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, points3, options1);
			glayer.addGeometry(polyline);
			var polyline = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, points4, options1);
			glayer.addGeometry(polyline);
			var polyline = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, points5, options1);
			glayer.addGeometry(polyline);

			//add view numbers
			var data = {
				// "1": "49.2010881N, 16.6206119E",
				// "2": "49.1993494N, 16.6164814E",
				// "3": "49.2002047N, 16.6108164E",
				// "4": "49.2032753N, 16.6085206E"

				 "1": "49.2010881N, 16.6206119E",
				 "1": "49.1993494N, 16.6164814E",
				 "2": "49.2002047N, 16.6108164E",
				 "3": "49.2032753N, 16.6085206E",
				 "4": "49.2064156N, 16.6095197E"

			};
			var znacky = [];
			var souradnice = [];
			var obr = 1;
			for (var name in data) {
				var coord = SMap.Coords.fromWGS84(data[name]);
				var options = {
                    url: 'Pics/' + obr + 'red.png'
					// url: 'Pics/' + obr + '.png'
				}
				var znacka = new SMap.Marker(coord, null, options);
				souradnice.push(coord);
				znacky.push(znacka);
				obr++;
			}
			var vrstva = new SMap.Layer.Marker();     /* Vrstva se značkami */
			m.addLayer(vrstva);                          /* Přidat ji do mapy */
			vrstva.enable();                         /* A povolit */
			for (var i = 0; i < znacky.length; i++) {
				vrstva.addMarker(znacky[i]);
			}
        }
    }
}
</script>

<style>
.mapa_meteoBlok{
    display: flex;
    flex-direction: column;
}
.mapaBlok, .meteoBlok{
    display: flex;
    flex-direction: column;
    max-height: 440px;
}
.hlavickaMapa, .hlavickaMeteo{
    background-color:  #0154CE;
    color: white;
    margin-top: 1px;
    height: 40px;
}
.mapa{
    max-height: 400px;
}
 .mapa img {
	max-width: 100vw;
	max-height: 400px;
	object-fit: cover;
 }

.hlavickaMapa p, .hlavickaMeteo p{
    text-align: center;
    top: 30%;
    font-weight: 700;
    margin-top: 8px;

}
</style>